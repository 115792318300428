import React, {useState, useEffect} from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import axios from '../../services/AxiosServices'
import parseHtml from 'html-react-parser'

const ProteccionDatosPersonales = () => {
    const [information, setInformation] = useState([]);

    const fetchData = async () => {
        try
        {
            const responseInformation = await axios.get(`/additionalInformations/?additionalInformationId=2`)
            setInformation(responseInformation.data[0]);
        }
        catch(error){
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

  return (<>
        <Container className='pt-4 pb-4 fadeIn'>
          <Row><Col className='text-center pb-3 article-title'><h1>Protección de Datos Personales</h1></Col></Row>
          <Row><Col className='pb-3'></Col></Row>
          <Row>
            {parseHtml(information.Description ?? "")}
          </Row>

        </Container>
    </>
  );
};

export default ProteccionDatosPersonales;