import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Modal, Button, Table, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import axios from '../../services/AxiosServices'
import parseHtml from 'html-react-parser'
import { saveAs } from 'file-saver';

const Articulo = () => {
  const { articleId } = useParams();
  
  const [show, setShow] = useState(false);
  const [article, setArticle] = useState({
    ArticleId: null,
    ArticleName: null,      
    ArticleOrder: null,
    Active: true
  });

  const [fractions, setFractions] = useState([]);
  const [fraction, setFraction] = useState(null)

  const handleClose = () => {
    setFraction(null);
    setShow(false)
  };

  const fetchData = async () => {
    try
    {
        const responseArticle = await axios.get(`/articles/?articleId=${articleId}`);
        setArticle(responseArticle.data[0]);

        const responseFractions = await axios.get(`/fractions/?articleId=${articleId}`);
        setFractions(responseFractions.data);
    }
    catch(error){
    }
  };

  const handleOpenFraction = async (fraction) => {
    const responseSections = await axios.get(`/sections/?fractionId=${fraction.FractionId}`);
    fraction.Sections = responseSections.data;

    for (const section of fraction.Sections) {
      try {
        let responseFiles = await axios.get(`/files/?sectionId=${section.SectionId}`);
        section.Files = responseFiles.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    setFraction(fraction);
    setShow(true);
  }

  const handleOpenFile = async (fileId) => {
    try
    {
      const response = await axios.get(`/files/?fileId=${fileId}`)
      const file = response.data[0];
      const base64String = file.FileData;

      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: file.FileType });

      saveAs(blob, file.FileName);
    }
    catch (error) {
    } 
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [articleId]);

  return (<>
    {fraction && <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title className='text-center'>{fraction.FractionName}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '600px', overflowY: 'auto' }}>
        <Row><Col className='pb-4'>{fraction.Description} </Col></Row>

        {fraction.Sections?.map((section) => (<>
          <Row><Col>{section.Description}</Col></Row>
          <Row>
            <Col className='pb-3'>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th className='text-center'>Mes</th>
                    <th className='text-center'>Año</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {section.Files?.map((file, index) => (
                  <tr key={index}>
                    <td className='text-center'>{file.ExerciseName}</td>
                    <td className='text-center'>{file.Year}</td>
                    <td className='text-center path'><Link onClick={() => handleOpenFile(file.FileId)}><FontAwesomeIcon icon={faFileExport} /></Link></td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Col>
          </Row>           
        </>))}
        
        <Row>
          <Col className='text-small'>
            Área Responsable<br />
            {fraction.AreaName}<br />
            Actualizado al {fraction.UpdatedAt}<br />
            Período de actualización: {fraction.PeriodName}<br />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
    }

    <Container className='pt-4 pb-4 fadeIn'>
      <Row><Col className='text-center pb-3 article-title'><h1>{article.ArticleName}</h1></Col></Row>
      <Row><Col className='pb-3'>{parseHtml(article.Description ?? "")}</Col></Row>
      
      <Row>
         {fractions.map((fraction, index) => (
          fraction.Show && <Col key={fraction.FractionId} xs={12} md={4} className='p-1' onClick={() => handleOpenFraction(fraction)}>
            <div className="d-flex align-items-center fraction  font-raleway">
              <span><Image src={`data:image/png;base64,${fraction.ImageData}`} className='fraction-img'/></span>
              <span>{fraction.FractionName} - {fraction.Title}</span>
            </div>
          </Col>
          ))}
      </Row>
    </Container>
    </>
  );
};

export default Articulo;