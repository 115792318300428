
import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Table, Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from '../../services/AxiosServices'
import { saveAs } from 'file-saver';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Documento = () => {
    const { fileId } = useParams();
    const [file, setFile] = useState([]);

    const fetchData = async () => {
        const response = await axios.get(`/files/?fileId=${fileId}`)
        const file = response.data[0];

        setFile(file);
    };

    const handleOpenFile = async (fileId) => {
      const base64String = file.FileData;

      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: file.FileType });

      saveAs(blob, file.FileName);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [fileId]);

    return (
    <Container className='pt-4 pb-4 fadeIn'>
    <Row><Col className='text-center'><Image src={`${process.env.PUBLIC_URL}/assets/img/logos/logo.png`} alt="Logo" width={"300px"}/></Col></Row>
    <Row>   
      <Col className='text-center pt-3'>
        <Table striped bordered>
            <thead>
              <tr>
                <th className='text-center'>Documento</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr >
                <td className='text-center'>{file.FileName}</td>
                <td className='text-center path'><Link onClick={() => handleOpenFile(file.FileId)}><FontAwesomeIcon icon={faFileExport} /></Link></td>
              </tr>
            </tbody>
          </Table>
      </Col>
    </Row>
  </Container>);
};

export default Documento;
