import axios from 'axios';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json'
  }
});

instance.interceptors.request.use(
  config => {
    config.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`
    console.log('Request sent:', config);
    return config;
  },
  error => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    console.log('Response received:', response);
    return response;
  },
  error => {
    if (error.response.data.code === '401') {
      localStorage.removeItem('accessToken');
      window.location.reload(false);
    }
    else {
      console.error('Response error:', error);
      return Promise.reject(error);
    }
  }
);

export default instance;