import React from 'react';
import { Container } from 'react-bootstrap';

const Header = () => {
  return (
    <header className="bg-White pt-3">
      <Container className="image-header fluid" style={{backgroundImage:`url(${process.env.PUBLIC_URL}/assets/img/encabezado.jpg)`}}>
      </Container>
    </header>
  );
};

export default Header;
 