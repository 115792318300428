import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

const Contact = () => {
  return (
    <Container className='pt-4 pb-4 fadeIn'>
      <Row><Col className='text-center pb-3 article-title'><h1>Contacto</h1></Col></Row>
      <Row>   
        <Col xs={12} sm={4} md={4} className='d-flex justify-content-center pb-4'>
          <Image src={`${process.env.PUBLIC_URL}/assets/img/mapa.jpg`} alt="Escudo"/>
        </Col>
        <Col xs={12} sm={6} md={6}>
          <h3>Información del Contacto de la Auditoría Superior del Estado</h3><br/> 
          Calle Loma Larga 2500 <br/> 
          Col. Obispado, Monterrey, N.L.<br/>
          México. C.P. 64060
          Tel. 812033-3400 Ext. 244<br/><br/> <br/> 

          <h3>Oficialia de Partes</h3><br/> 
          Calle Loma Larga 2550 <br/> 
          Col. Obispado, Monterrey, N.L.<br/>
          México. C.P. 64060
          Tel. 812033-3400 Ext. 250<br/>
        </Col>
      
      </Row>
    </Container>
  );
};

export default Contact;