import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import axios from '../../services/AxiosServices'
import { saveAs } from 'file-saver';

const AccesoInformacion = () => {
    const [applications, setApplications] = useState([]);

    const fetchData = async () => {
        try
        {
            const responseApplication = await axios.get(`/applications`);
            setApplications(responseApplication.data);
        }
        catch(error){
        }
    };

    const handleOpenFile = async (applicationId) => {
        try
        {
        const response = await axios.get(`/applications/?applicationId=${applicationId}`)
        const file = response.data[0];
        const base64String = file.FileData;

        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: file.FileType });

        saveAs(blob, file.AplicationName);
        }
        catch (error) {
        } 
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

  return (<>
        <Container className='pt-4 pb-4 fadeIn'>
          <Row><Col className='text-center pb-3 article-title'><h1>Solicitudes de Acceso a la Información</h1></Col></Row>
          <Row><Col className='pb-3'></Col></Row>
          <Row>
            <Col className='pb-3'>
              <Table striped bordered>
                <thead>
                  <tr>
                    <th className='text-center'>Expediente</th>
                    <th className='text-center'>Fecha Solicitud</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {applications.map((application, index) => (
                  <tr key={index}>
                    <td className='text-center'>{application.ApplicationName}</td>
                    <td className='text-center'>{application.ApplicationDate}</td>
                    <td className='text-center path'><Link onClick={() => handleOpenFile(application.ApplicationId)}><FontAwesomeIcon icon={faFileExport} /></Link></td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Col>
          </Row>           

        </Container>
    </>
  );
};

export default AccesoInformacion;