import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Image, Card, Modal } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import ListGroup from 'react-bootstrap/ListGroup'; 
import axios from '../services/AxiosServices'
import es from 'date-fns/locale/es';
import { format } from 'date-fns';
import parseHtml from 'html-react-parser'

const Home = () => {
  const [complete, setComplete] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [show, setShow] = useState(false);

  const [publications, setPublications] = useState([]);
  const [links, setLinks] = useState([]);
  const [information, setInformation] = useState([]);

  const handleClose = () => setShow(false);

  const fetchData = async () => {
    try
    {
        const responsePublications = await axios.get(`/publications/`)
        setPublications(responsePublications.data);

        const responseLinks = await axios.get(`/links/`)
        setLinks(responseLinks.data);

        const responseInformation = await axios.get(`/additionalInformations/?additionalInformationId=3`)
        setInformation(responseInformation.data[0]);

        setComplete(true);

        if (responseInformation.data[0].Description != ""){
          setShow(true);
        }
    }
    catch(error){
    }
  };
  
  useEffect(() => {
      fetchData();
  }, []);

  const handleItemClick = (index) => {
    setActiveIndex(index)
  };

  return (<>
  {complete &&
  <div>
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className='center' style={{ minHeight: '380px', maxHeight: '600px', minWidth: '480px'}}>
        <Row>
          <Col className='pb-4 text-center'>{parseHtml(information.Description ?? "")}</Col>
         </Row>
      </Modal.Body>
    </Modal>

    <Container fluid className='fadeIn' style={{background:"#26292b"}}>
      <Container>
        <Row>
          <Col xs={12} md={9} className='ps-0 pe-0'>
            <Carousel fade controls={true} indicators={true}>

            {publications.map((publication, index) => (
              <Carousel.Item interval={3000} key={index}>
                <Image src={`data:image/png;base64,${publication.ImageData}`} style={{width:"1000px", height:"500px"}}/>
                <Carousel.Caption>
                <Card className="transparent-card">
                  <Card.Body className="transparent-card-body">
                    <Card.Title className="transparent-card-title"><b>{publication.Title}</b></Card.Title>
                    <Card.Text className="transparent-card-text">
                    {parseHtml(publication.Description)}
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
            </Carousel>
          </Col>

          <Col xs={12} md={3} className='ps-0 pe-0'>
            <ListGroup as="ol" className="home-scrollable-list home-list-group">
            {publications.map((publication, index) => (
              <ListGroup.Item action as="li" key={index} onClick={() => handleItemClick({index})} className="home-list-group-item d-flex align-items-start">
                <span className="pull-left me-3"><Image src={`data:image/png;base64,${publication.ImageData}`}/></span>
                <span>{publication.Title}<br/><span className="home-item-date">{format(new Date(publication.PublicationDate), "dd/MM/yyyy", { locale : es })}</span></span>       
              </ListGroup.Item>
            ))}
            </ListGroup> 
          </Col>
        </Row>
      </Container>
    </Container>

    <Container className="mt-3 mb-5">
        <Row>
          {links.map((link, index) => (
            <Col key={link.LinkId} xs={12} md={index === 0 ? 12 : 4} className={index === 0 ? 'p-1 font-raleway' : 'p-2 font-raleway'} >
             {index === 0 && <a href={link.LinkPath} target='_blank' style={{textDecoration:"none"}}>
                <div className="d-flex align-items-center justify-content-center link text-center mb-3" style={{backgroundColor: link.LinkColor}}>
                  <span><h3 style={{marginBottom: "0px"}}><b>{link.Title}</b></h3>{link.Description}</span>
                </div>
              </a>}
             
              {index > 0 && <a href={link.LinkPath} target='_blank' style={{textDecoration:"none"}}>
                <div className="d-flex align-items-center link" style={{backgroundColor: link.LinkColor}}>
                  <span><Image src={`data:image/png;base64,${link.ImageData}`} className='link-img'/></span>
                  <span><h3 style={{marginBottom: "0px"}}><b>{link.Title}</b></h3>{link.Description}</span>
                </div>
              </a>}
            </Col>
            ))}
        </Row>
    </Container>

    <Container fluid className="p-0">
      <iframe border="0" title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3595.885524984699!2d-100.35053728546015!3d25.675089218447344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x866295ffb6229ef3%3A0xc02215c95d1f0c81!2sAuditoria+Superior+del+Estado+de+Nuevo+Le%C3%B3n!5e0!3m2!1ses!2smx!4v1453427351471" width="100%" height="250"  style={{"border": 0}}></iframe>
    </Container>
  </div>
  }
  </>
 );
};

export default Home;