import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="bg-dark text-light py-3">
    <Container className="mt-3 mb-3">
      <Row>
        <Col xs={6} sm={2} md={1} className='d-flex justify-content-center'>
          <Image src={`${process.env.PUBLIC_URL}/assets/img/escudofooter.png`} alt="Escudo"/>
        </Col>
        <Col xs={6} sm={5} md={4} className='pt-3' >
          <small>
          Auditoría Superior del Estado <br/> 
          Calle Loma Larga 2550 <br/> 
          Col. Obispado, Monterrey, N.L.<br/>
          México. C.P. 64060
          </small>
        </Col>
        <Col xs={12} md={6} className='text-center p-4 font-raleway'>
          <h3><b>Tel. 812033-3400 Ext. 244</b></h3>
        </Col>
      </Row>
    </Container>
    </footer>
  );
};

export default Footer;