import React, {useState, useEffect} from 'react';
import { Navbar as BootstrapNavbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faMagnifyingGlass, faEnvelope, faShieldAlt, faFileText, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from '../services/AxiosServices'

const Navbar = () => {
  const [articles, setArticles] = useState([]);

  const fetchData = async () => {
    //startLoading();
  
    try
    {
        const response = await axios.get(`/articles/`)
        setArticles(response.data)
        //stopLoading(); 
    }
    catch(error){
        //showMessage(Message.M001, MessageType.Error);
        //stopLoading();
    }
  };
  
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <BootstrapNavbar className="navbar bg-dark text-light py-1" expand="lg">
       <Container>
        <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav" />
        <BootstrapNavbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto text-light">
            <Nav.Link as={Link} to="/"><FontAwesomeIcon icon={ faHome }/> INICIO</Nav.Link>

            {/* <NavDropdown title={<span><FontAwesomeIcon icon={faIdBadge} /> IDENTIDAD</span>} id="basic-nav-dropdown" className="hover-dropdown">
              <div className="custom-caret"></div>
              <NavDropdown.Item href="#service1">¿Quiénes Somos?</NavDropdown.Item>
              <NavDropdown.Item href="#service2">Marco Legal</NavDropdown.Item>
              <NavDropdown.Item href="#service2">Organigrama</NavDropdown.Item>
              <NavDropdown.Item href="#service2">Directorio</NavDropdown.Item>
              <NavDropdown.Item href="#service2">Ant. de Fiscalización</NavDropdown.Item>
              <NavDropdown.Item href="#service2">Preguntas Frecuentes</NavDropdown.Item>
            </NavDropdown> */}

            <NavDropdown title={<span><FontAwesomeIcon icon={faMagnifyingGlass} /> TRANSPARENCIA</span>} id="basic-nav-dropdown" className="hover-dropdown">
              <div className="custom-caret"></div>
              <NavDropdown.Item href="#"><b>Sección de Transparencia Actual</b></NavDropdown.Item>

              {articles.map((article, index) => (
                    <NavDropdown.Item as={Link} to={`/transparencia/${article.ArticleId}`}>{article.ArticleName}</NavDropdown.Item>
              ))}
            </NavDropdown>

            <NavDropdown title={<span><FontAwesomeIcon icon={faFileText} /> SOLICITUDES</span>} id="basic-nav-dropdown" className="hover-dropdown">
              <div className="custom-caret"></div>
              <NavDropdown.Item as={Link} to={"/solicitudes/accesoInformacion"}>Solicitudes de Acceso a la Información</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={<span><FontAwesomeIcon icon={faNewspaper} /> INFORMACIÓN ADICIONAL</span>} id="basic-nav-dropdown" className="hover-dropdown">
              <div className="custom-caret"></div>
               <NavDropdown.Item as={Link} to={"/informacionAdicional/catalogoInformacionPublica"}>Catálogo de Información Pública</NavDropdown.Item>
              <NavDropdown.Item as={Link} to={"/informacionAdicional/proteccionDatosPersonales"}>Protección de Datos Personales</NavDropdown.Item>
            </NavDropdown>

 {/*            <NavDropdown title={<span><FontAwesomeIcon icon={faShieldAlt} /> ENTES A FISCALIZAR</span>} id="basic-nav-dropdown" className="hover-dropdown">
              <div className="custom-caret"></div>
              <NavDropdown.Item href="#service1">Municipios</NavDropdown.Item>
              <NavDropdown.Item href="#service2">Poder Ejecutivo</NavDropdown.Item>
              <NavDropdown.Item href="#service2">Poder Legislativo</NavDropdown.Item>
              <NavDropdown.Item href="#service2">Poder Judicial</NavDropdown.Item>
              <NavDropdown.Item href="#service2">Organismos Públicos Autónomo</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title={<span><FontAwesomeIcon icon={faNewspaper} /> INFORME DEL RESULTADO</span>} id="basic-nav-dropdown" className="hover-dropdown">
            <NavDropdown.Item href="#service1">Municipios</NavDropdown.Item>
              <div className="custom-caret"></div>
              <NavDropdown.Item href="#service2">Poder Ejecutivo</NavDropdown.Item>
              <NavDropdown.Item href="#service2">Poder Legislativo</NavDropdown.Item>
              <NavDropdown.Item href="#service2">Poder Judicial</NavDropdown.Item>
              <NavDropdown.Item href="#service2">Organismos Públicos Autónomo</NavDropdown.Item>
              <NavDropdown.Item href="#service2">Situaciones Excepcionales</NavDropdown.Item>
            </NavDropdown> */}

            <Nav.Link href="https://www.asenl.gob.mx" target='_blank'><FontAwesomeIcon icon={ faShieldAlt } /> PORTAL ANTERIOR</Nav.Link>
            <Nav.Link as={Link} to="/contacto"><FontAwesomeIcon icon={ faEnvelope }/> CONTACTO</Nav.Link>
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;
