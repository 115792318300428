import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import MainLayout from './layouts/MainLayout';
import Home from './pages/Home';
import Articulo from './pages/transparencia/Articulo';
import Documento from './pages/transparencia/Documento';

import AccesoInformacion from './pages/solicitudes/AccesoInformacion';

import CatalogoInformacionPublica from './pages/informacionAdicional/CatalogoInformacionPublica';
import ProteccionDatosPersonales from './pages/informacionAdicional/ProteccionDatosPersonales';

import Contacto from './pages/Contacto';

const App = () => {
  return (
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/transparencia/:articleId" element={<Articulo/>} />

            <Route path="/solicitudes/accesoInformacion" element={<AccesoInformacion/>} />

            <Route path="/informacionAdicional/catalogoInformacionPublica" element={<CatalogoInformacionPublica/>} />
            <Route path="/informacionAdicional/proteccionDatosPersonales" element={<ProteccionDatosPersonales/>} />
    
            <Route path="/contacto" element={<Contacto />} />
            <Route path="*" element={<Home />} />
          </Route>

          <Route path="/transparencia/documento/:fileId" element={<Documento/>} />
        </Routes>
      </Router>
  );
};

export default App;
